import { Entry_Pages_Contact } from '@graphql/generated'
import { MotionBard } from '@components/common/Bard/Bard'
import { motion } from 'framer-motion'
import Container from '@components/common/Container'
import Hero from '@components/common/Hero'
import ComponentLoader from '@components/component-loader/ComponentLoader'
import cx from 'classnames'
import ContactForm from '@components/forms/ContactForm'

type RequiredProps = {
  page: Entry_Pages_Contact
}

type OptionalProps = Partial<{}>

const ContactPage: React.FC<RequiredProps & OptionalProps> = ({ page }) => {
  const bgColor = page.background_color?.value ?? 'white'

  return (
    <main
      className={cx(
        'min-h-screen overflow-x-hidden',
        {
          white: 'bg-white',
          gray: 'bg-gray-50',
        }[bgColor] ?? 'bg-white'
      )}
    >
      <Hero
        page={{
          ...page,
          hero_type: { value: 'image_right' },
          hero_image: page.image,
          hero_text: [{ text: page.intro_text, type: 'text' }],
        }}
      >
        <div className="hidden lg:!mt-10 lg:block">
          <ContactForm
            form={page.form}
            confirm_text={page.confirm_text}
            button_text={page.button_text}
            thank_you_text={page.thank_you_text}
            error_text={page.error_text}
          />
        </div>
      </Hero>

      <section className="pb-20 lg:hidden">
        <Container fluid>
          <ContactForm
            form={page.form}
            confirm_text={page.confirm_text}
            button_text={page.button_text}
            thank_you_text={page.thank_you_text}
            error_text={page.error_text}
          />
        </Container>
      </section>

      <ComponentLoader group="components" components={page.components ?? []} />
    </main>
  )
}

export default ContactPage
