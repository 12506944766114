import React from 'react'
import cx from 'classnames'
import Container from '@components/common/Container'
import {
  Entry_Posts_Post,
  Entry_Team_TeamMember,
  GetEventsQueryVariables,
  GetPageBySlugQuery,
  GetPostsDocument,
  GetPostsQuery,
  GetPostsQueryVariables,
  GetSimilarPostsDocument,
  GetSimilarPostsQuery,
  GetSimilarPostsQueryVariables,
  LabeledValue,
  Maybe,
  Set_Components_Team,
  Set_TeamMemberComponents_Posts,
  useGetPageBySlugQuery,
  useGetPostsQuery,
  User,
} from '@graphql/generated'
import { TeamFilterContextProvider } from '@components/team/filters/Filter'
import TeamFormatFilter from '@components/team/filters/TeamFormatFilter'
import TeamMembersList from '@components/team/TeamMembersList'
import { useRouter } from 'next/router'
import { gqlFetcher, queryClient } from '@lib/fetcher'
import { useInfiniteQuery } from 'react-query'
import { motion } from 'framer-motion'
import Button from '@components/common/Button'
import PostCard from '@components/news/PostCard'
import PostsGrid from '@components/news/PostsGrid'

type Props = {
  post: Entry_Posts_Post
}

const NUMBER_OF_PAGES_TO_FETCH_AT_ONCE = 2

export const buildSimilarPostsVariables = (
  post: Pick<Entry_Posts_Post, 'id'>
): GetSimilarPostsQueryVariables => ({
  post_id: post.id,
  sort: 'date desc',
  limit: 4,
})

const SimilarPosts: React.FC<Props> = (props) => {
  const variables = buildSimilarPostsVariables(props.post)

  const postsQuery = useInfiniteQuery<GetSimilarPostsQuery>(
    ['GetSimilarPosts', variables],
    async ({ pageParam = 1 }) => {
      await queryClient.cancelQueries(['GetSimilarPosts'])

      return await gqlFetcher<GetSimilarPostsQuery, GetSimilarPostsQueryVariables>(
        GetSimilarPostsDocument,
        {
          ...variables,
          page: pageParam,
        }
      )()
    },
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage.similarPosts?.has_more_pages ? lastPage.similarPosts?.current_page + 1 : null,
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const fetchNextPages = (numberOfPages: number): void => {
    if (!numberOfPages) {
      return
    }

    postsQuery.fetchNextPage().then((result) => {
      if (result.hasNextPage) {
        fetchNextPages(numberOfPages - 1)
      }
    })
  }

  // Map all loaded infinite query pages into one array
  const posts: Array<Maybe<Entry_Posts_Post>> =
    postsQuery.data?.pages.flatMap(
      (page) => (page?.similarPosts?.data as Array<Maybe<Entry_Posts_Post>>) ?? []
    ) ?? []

  return (
    <PostsGrid
      title="Weitere Beiträge"
      posts={posts}
      maxCols={2}
      showLoadMore={postsQuery.hasNextPage}
      onLoadMore={() => fetchNextPages(NUMBER_OF_PAGES_TO_FETCH_AT_ONCE)}
    />
  )
}

SimilarPosts.defaultProps = {}

export default SimilarPosts
