import {
  GetSitemapQuery,
  GetSitemapQueryVariables,
  GetSitemapDocument,
  AssetInterface,
  Entry_Pages_Contact,
  Entry_Pages_News,
  Entry_Pages_Page,
  Entry_Posts_Post,
  Entry_Team_TeamMember,
  GetCategoriesDocument,
  GetCategoriesQuery,
  GetCategoriesQueryVariables,
  GetEventsDocument,
  GetEventsQuery,
  GetEventsQueryVariables,
  GetEventTypesDocument,
  GetEventTypesQuery,
  GetEventTypesQueryVariables,
  GetGlobalsDocument,
  GetGlobalsQuery,
  GetGlobalsQueryVariables,
  GetPageBySlugDocument,
  GetPageBySlugQuery,
  GetPageBySlugQueryVariables,
  GetPostsDocument,
  GetPostsQuery,
  GetPostsQueryVariables,
  GetTeamFormatsDocument,
  GetTeamFormatsQuery,
  GetTeamFormatsQueryVariables,
  GetSimilarPostsDocument,
  GetSimilarPostsQuery,
  GetSimilarPostsQueryVariables,
  GetTeamMemberForAuthorDocument,
  GetTeamMemberForAuthorQuery,
  GetTeamMemberForAuthorQueryVariables,
  GetNumberOfPostsByAuthorDocument,
  GetNumberOfPostsByAuthorQuery,
  GetNumberOfPostsByAuthorQueryVariables,
  GetTeamMembersDocument,
  GetTeamMembersQuery,
  GetTeamMembersQueryVariables,
  useGetPageBySlugQuery,
  GetFormatsQuery,
  GetFormatsQueryVariables,
  GetFormatsDocument,
  GetAuthorsQueryVariables,
  GetAuthorsQuery,
  GetAuthorsDocument,
  GetTagsQuery,
  GetTagsQueryVariables,
  GetTagsDocument,
  Sets_HeroText,
  Maybe,
} from '@graphql/generated'
import { createNewQueryClient, gqlFetcher, ResponseError } from '@lib/fetcher'
import type { GetStaticPaths, GetStaticPathsResult, NextPage } from 'next'
import { useRouter } from 'next/router'
import { dehydrate, InfiniteData, QueryClient, QueryKey, UseQueryOptions } from 'react-query'
import Container from '@components/common/Container'
import { MotionBard } from '@components/common/Bard/Bard'
import { motion } from 'framer-motion'
import { GetStaticPageProps, PageProps, QueryWithSeoData } from '@additional'
import NewsPage, { defaultGetPostsVariables } from '@components/pages/NewsPage'
import PagePage from '@components/pages/PagePage'
import ErrorComponent from '@components/common/Error'
import {
  defaultGetEventsVariables,
  defaultGetPastEventsVariables,
} from '@components/component-loader/components/Events'
import { defaultGetTeamMembersVariables } from '@components/component-loader/components/Team'
import TeamMemberPage from '@components/pages/TeamMemberPage'
import { buildTeamMemberPostsVariables } from '@components/component-loader/components/TeamMemberPosts'
import SimilarPosts, { buildSimilarPostsVariables } from '@components/news/SimilarPosts'
import PostPage from '@components/pages/PostPage'
import ContactPage from '@components/pages/ContactPage'
import { generateSrcForPostImage } from '@components/news/PostMedia'

type RequiredProps = {}

type OptionalProps = Partial<{
  errorCode: number
}>

function getHeroPlainText(heroText?: Maybe<Array<Maybe<Sets_HeroText>>>): string {
  return heroText?.map((textPart) => textPart?.text ?? '').join('') ?? ''
}

function buildMeta(page: QueryWithSeoData<GetPageBySlugQuery>['entry']): PageProps['meta'] {
  if (!page) {
    return {
      hidden: true,
    }
  }

  const meta = {
    hidden: page.seo_hidden ?? false,
    title: page.seo_title ?? page.title,
    description: page.seo_description ?? null,
    image: page.seo_image ?? null,
  }

  // Hero defaults
  if (!meta.description && 'hero_text' in page) {
    meta.description = getHeroPlainText(page.hero_text) ?? null
  }
  if (!meta.image && 'hero_image' in page) {
    meta.image = page.hero_image?.permalink ?? null
  }

  // Post defaults
  if (!meta.description && 'text' in page) {
    meta.description = page.text && page.text.length <= 320 ? page.text : null
  }
  if (!meta.description && 'content' in page) {
    const firstTextContent = page.content?.find((c) => c?.type === 'text') ?? null
    meta.description = firstTextContent && 'text' in firstTextContent ? firstTextContent.text : null
  }
  if (!meta.image && 'content' in page) {
    const firstImageContent = page.content?.find((c) => c?.type === 'image') ?? null
    meta.image =
      firstImageContent && 'image' in firstImageContent && firstImageContent.image
        ? generateSrcForPostImage(firstImageContent)
        : null
  }

  // TeamMember defaults
  if (!meta.description && 'biography' in page) {
    meta.description = page.biography && page.biography.length <= 320 ? page.biography : null
  }
  if (!meta.description && 'quote' in page) {
    meta.description = page.quote && page.quote.length <= 320 ? page.quote : null
  }
  if (!meta.image && 'image' in page) {
    const image = page.image ?? ('author' in page ? page.author?.avatar : null)
    meta.image = image?.permalink ?? null
  }

  return meta
}

const Uri: NextPage<RequiredProps & OptionalProps> = (props) => {
  const router = useRouter()

  let uri: string = Array.isArray(router.query.uri)
    ? router.query.uri.join('/')
    : router.query.uri ?? ''
  if (!uri.startsWith('/')) {
    uri = '/' + uri
  }

  const query = useGetPageBySlugQuery(
    { uri },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const page = query.data?.entry

  if (!page) {
    return <ErrorComponent statusCode={404} />
  }

  if (page.blueprint === 'news') {
    return <NewsPage page={page as Entry_Pages_News} />
  }

  if (page.blueprint === 'contact') {
    return <ContactPage page={page as Entry_Pages_Contact} />
  }

  if (page.blueprint === 'post') {
    return <PostPage page={page as Entry_Posts_Post} />
  }

  if (page.blueprint === 'team_member') {
    return <TeamMemberPage page={page as Entry_Team_TeamMember} />
  }

  if (page.blueprint === 'page') {
    return <PagePage page={page as Entry_Pages_Page} />
  }

  throw new Error(`Unexpected blueprint [${page.blueprint}]`)
}

// Weird fix required because current.pageParams initializes as `[undefined]` and undefined cannot be serialized to json.
function fixInfiniteQueryData<T>(queryClient: QueryClient, queryKey: QueryKey) {
  queryClient.setQueryData<InfiniteData<T>>(queryKey, (current) => {
    if (!current) {
      current = {
        pages: [],
        pageParams: [],
      }
    }

    current.pageParams = []

    return current
  })
}

export const getStaticProps: GetStaticPageProps<{}, { uri: string | string[] }> = async (
  context
) => {
  let uri: string =
    (Array.isArray(context.params?.uri) ? context.params?.uri.join('/') : context.params?.uri) ?? ''

  if (!uri.startsWith('/')) {
    uri = '/' + uri
  }

  const queryClient = createNewQueryClient()

  try {
    const data = await queryClient.fetchQuery(
      ['GetPageBySlug', { uri }],
      gqlFetcher<QueryWithSeoData<GetPageBySlugQuery>, GetPageBySlugQueryVariables>(
        GetPageBySlugDocument,
        { uri },
        {},
        undefined,
        context.previewData?.previewToken
      )
    )

    let errorCode: number | null = null
    if (!data?.entry) {
      throw new ResponseError('Entry not found', 404)
    }

    const page = data.entry

    const promises = [
      queryClient.prefetchQuery(
        ['GetGlobals'],
        gqlFetcher<GetGlobalsQuery, GetGlobalsQueryVariables>(
          GetGlobalsDocument,
          {},
          {},
          undefined,
          context.previewData?.previewToken
        )
      ),
    ]

    if (page.blueprint === 'news') {
      promises.push(
        queryClient
          .prefetchInfiniteQuery(['GetPosts', defaultGetPostsVariables], ({ pageParam = 1 }) =>
            gqlFetcher<GetPostsQuery, GetPostsQueryVariables>(
              GetPostsDocument,
              { ...defaultGetPostsVariables, page: pageParam },
              {},
              undefined,
              context.previewData?.previewToken
            )()
          )
          .then(() => {
            fixInfiniteQueryData<GetPostsQuery>(queryClient, ['GetPosts', defaultGetPostsVariables])
          })
      )
      promises.push(
        queryClient.prefetchQuery(
          ['GetCategories'],
          gqlFetcher<GetCategoriesQuery, GetCategoriesQueryVariables>(
            GetCategoriesDocument,
            {},
            {},
            undefined,
            context.previewData?.previewToken
          )
        )
      )
      promises.push(
        queryClient.prefetchQuery(
          ['GetFormats'],
          gqlFetcher<GetFormatsQuery, GetFormatsQueryVariables>(
            GetFormatsDocument,
            {},
            {},
            undefined,
            context.previewData?.previewToken
          )
        )
      )
      promises.push(
        queryClient.prefetchQuery(
          [
            'GetAuthors',
            {
              limit: 30,
              query: '',
            },
          ],
          gqlFetcher<GetAuthorsQuery, GetAuthorsQueryVariables>(
            GetAuthorsDocument,
            {
              limit: 30,
              query: '',
            },
            {},
            undefined,
            context.previewData?.previewToken
          )
        )
      )
      promises.push(
        queryClient.prefetchQuery(
          [
            'GetTags',
            {
              limit: 30,
              query: '',
            },
          ],
          gqlFetcher<GetTagsQuery, GetTagsQueryVariables>(
            GetTagsDocument,
            {
              limit: 30,
              query: '',
            },
            {},
            undefined,
            context.previewData?.previewToken
          )
        )
      )
    }

    if (page.blueprint === 'post' && 'author' in page && page.author?.id) {
      const variables: GetTeamMemberForAuthorQueryVariables = { userId: page.author.id }
      promises.push(
        queryClient.prefetchQuery(
          ['GetTeamMemberForAuthor', variables],
          gqlFetcher<GetTeamMemberForAuthorQuery, GetTeamMemberForAuthorQueryVariables>(
            GetTeamMemberForAuthorDocument,
            variables,
            {},
            undefined,
            context.previewData?.previewToken
          )
        )
      )
    }

    if (page.blueprint === 'post') {
      const variables = buildSimilarPostsVariables(page)
      promises.push(
        queryClient
          .prefetchInfiniteQuery(['GetSimilarPosts', variables], ({ pageParam = 1 }) =>
            gqlFetcher<GetSimilarPostsQuery, GetSimilarPostsQueryVariables>(
              GetSimilarPostsDocument,
              { ...variables, page: pageParam },
              {},
              undefined,
              context.previewData?.previewToken
            )()
          )
          .then(() => {
            fixInfiniteQueryData<GetSimilarPostsQuery>(queryClient, ['GetSimilarPosts', variables])
          })
      )
    }

    if (page.blueprint === 'team_member' && 'author' in page) {
      const variables: GetNumberOfPostsByAuthorQueryVariables = { userId: page.author?.id }
      promises.push(
        queryClient.prefetchQuery(
          ['GetNumberOfPostsByAuthor', variables],
          gqlFetcher<GetNumberOfPostsByAuthorQuery, GetNumberOfPostsByAuthorQueryVariables>(
            GetNumberOfPostsByAuthorDocument,
            variables,
            {},
            undefined,
            context.previewData?.previewToken
          )
        )
      )

      // If the team member page has a posts component
      if (
        'team_member_components' in page &&
        page.team_member_components?.some((c) => c?.type === 'posts')
      ) {
        promises.push(
          queryClient
            .prefetchInfiniteQuery(
              ['GetPosts', buildTeamMemberPostsVariables({ id: page.author?.id ?? null })],
              ({ pageParam = 1 }) =>
                gqlFetcher<GetPostsQuery, GetPostsQueryVariables>(
                  GetPostsDocument,
                  {
                    ...buildTeamMemberPostsVariables({ id: page.author?.id ?? null }),
                    page: pageParam,
                  },
                  {},
                  undefined,
                  context.previewData?.previewToken
                )()
            )
            .then(() => {
              fixInfiniteQueryData<GetPostsQuery>(queryClient, [
                'GetPosts',
                buildTeamMemberPostsVariables({ id: page.author?.id ?? null }),
              ])
            })
        )
      }
    }

    if ('components' in page) {
      // If the page has an events component
      if (page.components?.some((c) => c?.type === 'events')) {
        promises.push(
          queryClient
            .prefetchInfiniteQuery(['GetEvents', defaultGetEventsVariables], ({ pageParam = 1 }) =>
              gqlFetcher<GetEventsQuery, GetEventsQueryVariables>(
                GetEventsDocument,
                { ...defaultGetEventsVariables, page: pageParam },
                {},
                undefined,
                context.previewData?.previewToken
              )()
            )
            .then(() => {
              fixInfiniteQueryData<GetEventsQuery>(queryClient, [
                'GetEvents',
                defaultGetEventsVariables,
              ])
            })
        )
        promises.push(
          queryClient
            .prefetchInfiniteQuery(
              ['GetEvents', defaultGetPastEventsVariables],
              ({ pageParam = 1 }) =>
                gqlFetcher<GetEventsQuery, GetEventsQueryVariables>(
                  GetEventsDocument,
                  { ...defaultGetPastEventsVariables, page: pageParam },
                  {},
                  undefined,
                  context.previewData?.previewToken
                )()
            )
            .then(() => {
              fixInfiniteQueryData<GetEventsQuery>(queryClient, [
                'GetEvents',
                defaultGetPastEventsVariables,
              ])
            })
        )
        promises.push(
          queryClient.prefetchQuery(
            ['GetEventTypes'],
            gqlFetcher<GetEventTypesQuery, GetEventTypesQueryVariables>(
              GetEventTypesDocument,
              {},
              {},
              undefined,
              context.previewData?.previewToken
            )
          )
        )
      }

      // If the page has an team component
      if (page.components?.some((c) => c?.type === 'team')) {
        promises.push(
          queryClient.prefetchQuery(['GetTeamMembers', defaultGetTeamMembersVariables], () =>
            gqlFetcher<GetTeamMembersQuery, GetTeamMembersQueryVariables>(
              GetTeamMembersDocument,
              { ...defaultGetTeamMembersVariables },
              {},
              undefined,
              context.previewData?.previewToken
            )()
          )
        )
        promises.push(
          queryClient.prefetchQuery(
            ['GetTeamFormats'],
            gqlFetcher<GetTeamFormatsQuery, GetTeamFormatsQueryVariables>(
              GetTeamFormatsDocument,
              {},
              {},
              undefined,
              context.previewData?.previewToken
            )
          )
        )
      }
    }

    await Promise.all(promises)

    return {
      revalidate: 20, // In seconds

      props: {
        dehydratedState: dehydrate(queryClient),

        meta: buildMeta(page),
      },
    }
  } catch (error) {
    const status: number = error instanceof ResponseError ? error.status : 500

    return {
      revalidate: 2, // In seconds
      notFound: status === 404,
      props: {
        errorCode: status,
      },
    }
  }
}

export const getStaticPaths: GetStaticPaths = async (context) => {
  const sitemap = await gqlFetcher<GetSitemapQuery, GetSitemapQueryVariables>(GetSitemapDocument)()

  const paths: GetStaticPathsResult['paths'] =
    sitemap.sitemap
      ?.filter((entry) => entry?.path)
      ?.map((entry) => ({ params: { uri: [entry!.path || '/'] } })) ?? []

  return {
    paths,
    fallback: 'blocking',
  }
}

export default Uri
