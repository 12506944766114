import {
  Entry_Posts_Post,
  GetSimilarPostsQueryVariables,
  useGetTeamMemberForAuthorQuery,
} from '@graphql/generated'
import Bard, { MotionBard } from '@components/common/Bard/Bard'
import { motion } from 'framer-motion'
import Container from '@components/common/Container'
import ArrowLink from '@components/common/ArrowLink'
import LikeButton from '@components/news/LikeButton'
import { format, isToday, parseISO } from 'date-fns'
import { de } from 'date-fns/locale'
import Avatar from '@components/common/Avatar'
import AppLink from '@components/common/AppLink'
import FacebookIcon from '@components/icons/FacebookIcon'
import InstagramIcon from '@components/icons/InstagramIcon'
import TwitterIcon from '@components/icons/TwitterIcon'
import WhatsappIcon from '@components/icons/WhatsappIcon'
import PostMedia from '@components/news/PostMedia'
import ShareButton from '@components/common/ShareButton'
import LinkIcon from '@components/icons/LinkIcon'
import ShareIcon from '@components/icons/ShareIcon'
import Link from '@components/common/Link'
import SimilarPosts from '@components/news/SimilarPosts'
import cx from 'classnames'
import Reveal, { RevealChild } from '@components/common/Reveal'

type RequiredProps = {
  page: Entry_Posts_Post
}

type OptionalProps = Partial<{}>

function formatDate(date?: string | null): string | null {
  if (!date) {
    return null
  }

  const parsedDate = parseISO(date)

  const day = isToday(parsedDate) ? 'Heute' : format(parsedDate, 'd. MMMM yyyy', { locale: de })
  const time = format(parsedDate, 'HH:mm', { locale: de })

  return `${day}, ${time} Uhr`
}

const PostPage: React.FC<RequiredProps & OptionalProps> = ({ page }) => {
  const teamMemberQuery = useGetTeamMemberForAuthorQuery(
    {
      userId: page.author?.id,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const sharerUrl = process.env.NEXT_PUBLIC_APP_URL!.toString() + page.url
  const sharerUrlEncoded = encodeURIComponent(sharerUrl)

  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${sharerUrlEncoded}`
  const twitterUrl = `https://twitter.com/share?url=${sharerUrlEncoded}`
  const whatsappUrl = `https://wa.me/?text=${sharerUrlEncoded}`

  return (
    <main className="min-h-screen bg-gray-50 pb-[calc(var(--footer-height)+theme(padding.20))] md:pb-[calc(var(--footer-height)+theme(padding.44))]">
      <div className="bg-white pt-h-logo md:bg-transparent">
        <Container fluid className="pt-9">
          <div className="mx-auto grid max-w-8xl gap-x-6 lg:grid-cols-[14.375rem,auto]">
            <aside className="row-span-full hidden lg:block">
              <Reveal
                variants={{ hidden: { y: 0, x: 20 }, visible: { x: 0 } }}
                transition={{ delay: 0.2 }}
                className="sticky top-[calc(var(--logo-height)+theme(spacing.9))]"
              >
                <ArrowLink
                  href="/"
                  reverse
                  size="small"
                  className="typo-100 w-full !justify-start space-x-2 bg-white px-5 py-4"
                >
                  <span>Zurück</span>
                </ArrowLink>
              </Reveal>
            </aside>

            <div className="grid gap-x-6 md:grid-cols-[1.5fr,1fr] xl:grid-cols-[auto,20.75rem]">
              <div className="">
                <article className="bg-white">
                  <div>
                    <div className="pb-8 md:px-8 md:pt-7 md:pb-9">
                      <div className="mb-8 flex items-center justify-between lg:hidden">
                        <ArrowLink href="/" reverse size="small" className="typo-100 space-x-2">
                          <span>Zurück</span>
                        </ArrowLink>

                        <LikeButton post={page} className="typo-300 whitespace-nowrap md:hidden">
                          {(likes) => `${likes} Likes`}
                        </LikeButton>
                      </div>

                      <div className="space-y-5 md:space-y-6">
                        <Reveal transition={{ delayChildren: 0.5 }} className="space-y-5">
                          <div className="space-y-2">
                            <RevealChild>
                              <p
                                title="Veröffentlichungsdatum"
                                className="typo-100-regular text-gray-500"
                              >
                                {/* @ts-ignore */}
                                <time pubdate="pubdate" dateTime={page.date ?? ''}>
                                  {formatDate(page.date)}
                                </time>
                              </p>
                            </RevealChild>
                            <RevealChild>
                              <h1 className="typo-1100">{page.title}</h1>
                            </RevealChild>
                          </div>

                          {page.author &&
                            (teamMemberQuery.data?.entry?.url ? (
                              <RevealChild>
                                <Link
                                  rel="author"
                                  href={teamMemberQuery.data.entry.url}
                                  className="typo-200 inline-flex items-center space-x-3 md:hidden"
                                >
                                  <Avatar user={page.author} className="h-8 w-8" />
                                  <span>{page.author.name}</span>
                                </Link>
                              </RevealChild>
                            ) : (
                              <RevealChild className="typo-200 inline-flex items-center space-x-3 md:hidden">
                                <Avatar user={page.author} className="h-8 w-8" />
                                <span>{page.author.name}</span>
                              </RevealChild>
                            ))}
                        </Reveal>

                        {page.text && (
                          <RevealChild>
                            <Bard html={page.text} className="typo-200-regular" />
                          </RevealChild>
                        )}

                        <div className="space-y-8">
                          <Reveal transition={{ delayChildren: 1 }} className="space-y-6">
                            {page.content?.map((item, i) =>
                              !item ? null : (
                                <RevealChild key={i}>
                                  <figure className="space-y-1.5">
                                    <PostMedia
                                      content={item}
                                      lazy={i > 1}
                                      allowGrowing
                                      allowClickInside
                                      className="w-full"
                                    />
                                    {page.author && (
                                      <figcaption className="typo-100-regular mt-1 text-xs">
                                        © Copyright by {page.author.name}
                                      </figcaption>
                                    )}
                                  </figure>
                                </RevealChild>
                              )
                            )}
                          </Reveal>

                          <div className="space-y-6 md:hidden">
                            {Array.isArray(page.categories) && (
                              <Reveal>
                                <dl className="space-y-2">
                                  <dt id="categories-mobile" className="typo-100-regular">
                                    <RevealChild>Kategorien</RevealChild>
                                  </dt>
                                  <dd>
                                    <ul
                                      aria-labelledby="categories-mobile"
                                      className="inline-flex flex-wrap items-center gap-x-3.5 gap-y-3"
                                    >
                                      {page.categories?.map(
                                        (category) =>
                                          category && (
                                            <li key={category.slug}>
                                              <RevealChild>
                                                <AppLink
                                                  href={`/?category=${category.slug}`}
                                                  className="typo-100 inline-block bg-red-500 px-5 py-2 text-white outline-none transition hover:bg-red-600 focus-visible:ring"
                                                >
                                                  {category.title}
                                                </AppLink>
                                              </RevealChild>
                                            </li>
                                          )
                                      )}
                                    </ul>
                                  </dd>
                                </dl>
                              </Reveal>
                            )}
                            {Array.isArray(page.tags) && (
                              <Reveal>
                                <dl className="space-y-2.5">
                                  <dt id="tags-mobile" className="typo-100-regular">
                                    <RevealChild>Tags</RevealChild>
                                  </dt>
                                  <dd>
                                    <ul
                                      aria-labelledby="tags-mobile"
                                      className="inline-flex flex-wrap items-center gap-x-2.5 gap-y-2"
                                    >
                                      {page.tags.map(
                                        (tag) =>
                                          tag && (
                                            <li key={tag.slug}>
                                              <RevealChild>
                                                <AppLink
                                                  href={`/?tags=${encodeURI(
                                                    JSON.stringify([tag.slug])
                                                  )}`}
                                                  className="typo-100-regular inline-block bg-blue-200 px-3 py-1 text-blue-900 outline-none transition hover:bg-blue-300 focus-visible:ring"
                                                >
                                                  {tag.title}
                                                </AppLink>
                                              </RevealChild>
                                            </li>
                                          )
                                      )}
                                    </ul>
                                  </dd>
                                </dl>
                              </Reveal>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <hr className="-mx-6 border-t-2 border-gray-100 sm:-mx-10 md:mx-0" />

                      <Reveal className="flex items-center justify-between py-6 md:px-8">
                        <div>
                          <RevealChild>
                            <ShareButton
                              tabIndex={-1}
                              value={sharerUrl}
                              title={page.title}
                              className="typo-100-regular"
                            >
                              Beitrag teilen
                            </ShareButton>
                          </RevealChild>
                        </div>
                        <div className="flex items-center gap-x-5 xs:gap-x-7">
                          <RevealChild className="flex items-center justify-center">
                            <AppLink
                              href={facebookUrl}
                              className="outline-none ring-offset-2 transition hover:text-red-500 focus-visible:ring"
                            >
                              <span className="sr-only">Auf Facebook teilen</span>
                              <FacebookIcon className="h-5 w-5" />
                            </AppLink>
                          </RevealChild>
                          <RevealChild className="flex items-center justify-center">
                            <AppLink
                              href={twitterUrl}
                              className="outline-none ring-offset-2 transition hover:text-red-500 focus-visible:ring"
                            >
                              <span className="sr-only">Auf Twitter teilen</span>
                              <TwitterIcon className="h-5 w-5" />
                            </AppLink>
                          </RevealChild>
                          <RevealChild className="flex items-center justify-center">
                            <AppLink
                              href={whatsappUrl}
                              className="outline-none ring-offset-2 transition hover:text-red-500 focus-visible:ring"
                            >
                              <span className="sr-only">Auf Whatsapp teilen</span>
                              <WhatsappIcon className="h-5 w-5" />
                            </AppLink>
                          </RevealChild>
                          <RevealChild className="flex items-center justify-center">
                            <ShareButton
                              value={sharerUrl}
                              title={page.title}
                              className="outline-none ring-offset-2 transition hover:text-red-500 focus-visible:ring"
                            >
                              <span className="sr-only">Teilen</span>
                              <ShareIcon className="h-4 w-4" />
                            </ShareButton>
                          </RevealChild>
                        </div>
                      </Reveal>
                    </div>
                  </div>
                </article>

                <section className="hidden pt-14 lg:block">
                  <SimilarPosts post={page} />
                </section>
              </div>

              <aside className="hidden md:block">
                <div className="sticky top-[calc(var(--logo-height)+theme(spacing.9))] bg-white px-8 pb-11 pt-7">
                  <div className="space-y-6">
                    {Array.isArray(page.categories) && (
                      <Reveal transition={{ delayChildren: 0.2 }}>
                        <dl className="space-y-2">
                          <dt id="categories-desktop" className="typo-100-regular">
                            <RevealChild>Kategorien</RevealChild>
                          </dt>
                          <dd>
                            <ul
                              aria-labelledby="categories-desktop"
                              className="inline-flex flex-wrap items-center gap-x-3.5 gap-y-3"
                            >
                              {page.categories.map(
                                (category) =>
                                  category && (
                                    <li key={category.slug}>
                                      <RevealChild>
                                        <AppLink
                                          href={`/?category=${category.slug}`}
                                          className="typo-100 inline-block bg-red-500 px-5 py-2 text-white outline-none transition hover:bg-red-600 focus-visible:ring"
                                        >
                                          {category.title}
                                        </AppLink>
                                      </RevealChild>
                                    </li>
                                  )
                              )}
                            </ul>
                          </dd>
                        </dl>
                      </Reveal>
                    )}

                    {Array.isArray(page.tags) && (
                      <Reveal transition={{ delayChildren: 0.4 }}>
                        <dl className="space-y-2.5">
                          <dt id="tags-desktop" className="typo-100-regular">
                            <RevealChild>Tags</RevealChild>
                          </dt>
                          <dd>
                            <ul
                              aria-labelledby="tags-desktop"
                              className="inline-flex flex-wrap items-center gap-x-2.5 gap-y-2"
                            >
                              {page.tags.map(
                                (tag) =>
                                  tag && (
                                    <li key={tag.slug}>
                                      <RevealChild>
                                        <AppLink
                                          href={`/?tags=${encodeURI(JSON.stringify([tag.slug]))}`}
                                          className="typo-100-regular inline-block bg-blue-200 px-3 py-1 text-blue-900 outline-none transition hover:bg-blue-300 focus-visible:ring"
                                        >
                                          {tag.title}
                                        </AppLink>
                                      </RevealChild>
                                    </li>
                                  )
                              )}
                            </ul>
                          </dd>
                        </dl>
                      </Reveal>
                    )}

                    {page.format && (
                      <Reveal transition={{ delayChildren: 0.6 }}>
                        <dl className="space-y-2">
                          <dt id="format-desktop" className="typo-100-regular">
                            <RevealChild>Format</RevealChild>
                          </dt>
                          <dd>
                            <RevealChild>
                              <Link
                                href={`/?formats=${encodeURI(JSON.stringify([page.format.slug]))}`}
                                className="typo-200 inline-block"
                              >
                                {page.format?.title}
                              </Link>
                            </RevealChild>
                          </dd>
                        </dl>
                      </Reveal>
                    )}

                    {page.author && (
                      <Reveal transition={{ delayChildren: 0.8 }}>
                        <dl className="space-y-2">
                          <dt id="author-desktop" className="typo-100-regular">
                            <RevealChild>Künstler:in</RevealChild>
                          </dt>
                          {teamMemberQuery.data?.entry?.url ? (
                            <dd>
                              <RevealChild>
                                <Link
                                  rel="author"
                                  href={teamMemberQuery.data.entry.url}
                                  className="typo-200 inline-flex items-center space-x-3"
                                >
                                  <Avatar user={page.author} className="h-8 w-8" />
                                  <span>{page.author.name}</span>
                                </Link>
                              </RevealChild>
                            </dd>
                          ) : (
                            <dd className="typo-200 inline-flex items-center space-x-3">
                              <RevealChild>
                                <Avatar user={page.author} className="h-8 w-8" />
                                <span>{page.author.name}</span>
                              </RevealChild>
                            </dd>
                          )}
                        </dl>
                      </Reveal>
                    )}

                    <Reveal transition={{ delayChildren: 1 }}>
                      <dl className="space-y-2">
                        <dt id="likes" className="typo-100-regular">
                          <RevealChild>Likes</RevealChild>
                        </dt>
                        <dd>
                          <RevealChild>
                            <LikeButton post={page} className="typo-200 whitespace-nowrap">
                              {(likes) => `${likes} Likes`}
                            </LikeButton>
                          </RevealChild>
                        </dd>
                      </dl>
                    </Reveal>
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </Container>
      </div>

      <section className="pt-6 pb-10 md:pt-14 md:pb-20 lg:hidden">
        <Container fluid className="">
          <SimilarPosts post={page} />
        </Container>
      </section>
    </main>
  )
}

export default PostPage
