import cx from 'classnames'
import Bard from '@components/common/Bard/Bard'
import { Entry_Pages_Page, Maybe } from '@graphql/generated'
import StatamicImage from '@components/common/StatamicImage'
import Button from '@components/common/Button'
import { PropsWithChildren } from 'react'
import Reveal, { RevealChild } from '@components/common/Reveal'
import ComponentLoader from '@components/component-loader/ComponentLoader'

type Props = {
  page: Partial<
    Pick<
      Entry_Pages_Page,
      | 'background_color'
      | 'title'
      | 'hero_type'
      | 'hero_image'
      | 'hero_text'
      | 'hero_link'
      | 'hero_link_text'
    >
  >
}

const Hero: React.FC<PropsWithChildren<Props>> = ({ page, children }) => {
  const bgColor = page.background_color?.value ?? 'white'

  if (
    page.hero_type?.value &&
    ['image_full_width', 'image_right'].includes(page?.hero_type?.value) &&
    page.hero_image
  ) {
    return (
      <section
        className={cx({
          'lg:flex lg:min-h-[min(100vh,60rem)] lg:flex-row-reverse lg:items-stretch':
            page.hero_type.value === 'image_right',
        })}
      >
        <StatamicImage
          asset={page.hero_image}
          className={cx('h-85 select-none object-cover', {
            'w-full md:h-[50vh]': page.hero_type.value === 'image_full_width',
            'md:h-[50vh] lg:h-auto lg:w-1/2 2xl:w-3/5 3xl:w-2/3':
              page.hero_type.value === 'image_right',
          })}
        />

        <div
          className={cx(
            'isolate inline-block w-full px-4',
            {
              'lg:w-1/2 lg:bg-transparent lg:px-0': page.hero_type.value === 'image_right',
            },
            {
              white: 'bg-white',
              gray: 'bg-white sm:bg-gray-50',
            }[bgColor] ?? 'bg-white'
          )}
        >
          <div
            className={cx(
              'relative mx-auto -mt-12 w-full max-w-[20rem] px-10 pt-6 pb-12 text-center sm:max-w-md md:max-w-2xl md:px-20 md:pt-10 lg:max-w-3xl',
              'before:absolute before:inset-0 before:-z-1 before:-translate-x-6 before:-skew-x-13 sm:before:translate-x-0',
              {
                'lg:mt-0 lg:h-full lg:max-w-none lg:items-start lg:pb-20 lg:pt-[calc(var(--logo-height)+5rem)] lg:pl-20 lg:pr-0 lg:text-left lg:before:inset-y-0 lg:before:left-auto lg:before:aspect-square lg:before:h-full lg:before:w-auto lg:before:translate-x-[11.5%] xl:pr-20 xl:pl-36':
                  page.hero_type.value === 'image_right',
              },
              page.hero_type.value === 'image_right'
                ? {
                    white: 'before:bg-white lg:bg-white',
                    gray: 'before:bg-white lg:bg-gray-50 lg:before:bg-gray-50',
                  }[bgColor] ?? 'bg-white'
                : 'before:bg-white'
            )}
          >
            <Reveal
              transition={{ delayChildren: 0.5 }}
              className={cx('space-y-9 md:space-y-10', {
                'lg:ml-auto lg:flex lg:h-full lg:max-w-lg lg:flex-col':
                  page.hero_type.value === 'image_right',
              })}
            >
              <div className="space-y-8 md:space-y-5">
                <RevealChild>
                  <h1 className="typo-1200">{page.title}</h1>
                </RevealChild>

                <RevealChild>
                  <div
                    className={cx('-mx-10 text-lg xs:-mx-20 lg:mx-0', {
                      'font-semibold md:text-xl lg:text-2xl':
                        page.hero_type.value === 'image_full_width',
                      'lg:text-xl': page.hero_type.value === 'image_right',
                    })}
                  >
                    <ComponentLoader group="hero-components" components={page.hero_text ?? []} />
                  </div>
                </RevealChild>
              </div>

              {page.hero_link && (
                <RevealChild
                  className={cx({
                    'lg:flex lg:flex-1 lg:flex-col lg:items-start':
                      page.hero_type.value === 'image_right',
                  })}
                >
                  {page.hero_type.value === 'image_right' && (
                    <div className="hidden max-h-25 flex-1 lg:block"></div>
                  )}
                  <Button href={page.hero_link}>{page.hero_link_text}</Button>
                </RevealChild>
              )}

              {children}
            </Reveal>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section
      className={cx(
        'pt-h-logo',
        {
          white: 'bg-white',
          gray: 'bg-gray-50',
        }[bgColor] ?? 'bg-white'
      )}
    >
      <Reveal
        transition={{ delayChildren: 0.5 }}
        className="mx-auto w-full max-w-2xl space-y-8 px-6 pt-22 pb-16 text-center md:pb-22 md:pt-30"
      >
        <RevealChild>
          <h1 className="typo-1200">{page.title}</h1>
        </RevealChild>

        <RevealChild>
          <ComponentLoader group="hero-components" components={page.hero_text ?? []} />
        </RevealChild>
      </Reveal>
    </section>
  )
}

export default Hero
