import React, { FC, HTMLProps } from 'react'
import { FormFieldChildProps } from '@components/forms/fields/FormField'
import cx from 'classnames'
import { useLabelContext } from '@radix-ui/react-label'

type TextAreaProps = Partial<
  // TODO: Remove unnecessary type addition.
  FormFieldChildProps<string> & HTMLProps<HTMLTextAreaElement>
>

const TextArea: FC<TextAreaProps> = ({ className, meta, ...props }) => {
  const labelId = useLabelContext()

  return (
    <textarea
      {...props}
      aria-labelledby={labelId}
      aria-describedby={`${labelId}-error`}
      aria-invalid={!!meta?.error && meta?.touched}
      className={cx(
        'border border-gray-50 bg-gray-50 px-4 py-3.5 outline-none transition placeholder:text-blue-900/50 hover:border-gray-100 hover:bg-gray-100 focus:border-blue-900',
        'disabled:text-blue-900/60 disabled:hover:border-gray-50 disabled:hover:bg-gray-50',
        className,
        {
          '!border-red-500 !bg-red-50': meta?.error && meta?.touched,
        }
      )}
    ></textarea>
  )
}

export default React.memo(TextArea)
