import { IconProps } from '@additional'

const WhatsappIcon: React.FC<IconProps> = (props) => {
  return (
    <svg version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path
          d="m479.95 435.77-53.184 27.441c12.75 37.246 28.828 68.766 44.633 94.273l41.906-57.871z"
          fill="currentColor"
        />
        <path
          d="m637.59 720.93c26.961 17.156 58.266 33.637 92.699 45.73l27.422-53.129-63.852-33.355z"
          fill="currentColor"
        />
        <path
          d="m614.14 181.96c-230.51 0-418.04 187.54-418.04 418.04 0 70.527 17.914 140.04 51.883 201.64l-78.73 190.48c-2.7383 6.6172-1.4609 14.203 3.2734 19.566 3.6094 4.0977 8.7578 6.3477 14.062 6.3477 1.6484 0 3.3203-0.21484 4.9492-0.66406l209.93-57.488c64.285 38.07 137.63 58.152 212.68 58.152 230.5 0 418.04-187.53 418.04-418.04 0-230.52-187.55-418.03-418.05-418.03zm185.46 532.2-43.305 83.898c-3.2891 6.3672-9.7969 10.152-16.66 10.152-1.8203 0-3.6562-0.26172-5.4766-0.81641-127.29-38.859-215.97-133.39-225.61-143.97-8.9609-7.9688-83.719-77.195-122.46-204.09-2.6445-8.6641 1.2852-17.98 9.3359-22.133l83.906-43.293c9.1602-4.7344 20.438-1.1719 25.211 7.9766l47.363 90.656c3.3008 6.3008 2.7383 13.922-1.4258 19.68l-56.633 78.207c22.238 29.664 39.816 45.168 40.172 45.488 0.30078 0.25391 0.51562 0.57031 0.78906 0.83594 0.30078 0.28906 0.64844 0.52344 0.92969 0.84375 0.46875 0.52344 26.203 28.949 69.086 60.777l76.445-55.359c5.7578-4.1719 13.379-4.7148 19.68-1.4336l90.664 47.371c9.1484 4.7656 12.711 16.047 7.9844 25.215z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

WhatsappIcon.defaultProps = {
  'aria-hidden': true,
}

export default WhatsappIcon
