import { Entry_Team_TeamMember } from '@graphql/generated'
import Hero from '@components/common/Hero'
import ComponentLoader from '@components/component-loader/ComponentLoader'
import cx from 'classnames'
import HeroTeamMember from '@components/common/HeroTeamMember'

type RequiredProps = {
  page: Entry_Team_TeamMember
}

type OptionalProps = Partial<{}>

const TeamMemberPage: React.FC<RequiredProps & OptionalProps> = ({ page }) => {
  return (
    <main className={cx('min-h-screen overflow-x-hidden bg-gray-50')}>
      <HeroTeamMember page={page} />

      <ComponentLoader
        group="team-member-components"
        components={page.team_member_components ?? []}
      />
    </main>
  )
}

export default TeamMemberPage
