import { Entry_Pages_Page } from '@graphql/generated'
import { MotionBard } from '@components/common/Bard/Bard'
import { motion } from 'framer-motion'
import Container from '@components/common/Container'
import Hero from '@components/common/Hero'
import ComponentLoader from '@components/component-loader/ComponentLoader'
import cx from 'classnames'

type RequiredProps = {
  page: Entry_Pages_Page
}

type OptionalProps = Partial<{}>

const PagePage: React.FC<RequiredProps & OptionalProps> = ({ page }) => {
  const bgColor = page.background_color?.value ?? 'white'

  return (
    <main
      className={cx(
        'min-h-screen overflow-x-hidden',
        {
          white: 'bg-white',
          gray: 'bg-gray-50',
        }[bgColor] ?? 'bg-white'
      )}
    >
      <Hero page={page} />
      <ComponentLoader group="components" components={page.components ?? []} />
    </main>
  )
}

export default PagePage
