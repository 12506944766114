import cx from 'classnames'
import { motion } from 'framer-motion'
import {
  Entry_Team_TeamMember,
  Term_TeamFormats_TeamFormat,
  useGetNumberOfPostsByAuthorQuery,
} from '@graphql/generated'
import Container from '@components/common/Container'
import StatamicImage from '@components/common/StatamicImage'
import Bard from '@components/common/Bard/Bard'
import { format, parseISO } from 'date-fns'
import Button from '@components/common/Button'
import ChainIcon from '@components/icons/ChainIcon'
import AppLink from '@components/common/AppLink'
import FacebookIcon from '@components/icons/FacebookIcon'
import LinkedInIcon from '@components/icons/LinkedInIcon'
import InstagramIcon from '@components/icons/InstagramIcon'
import TwitterIcon from '@components/icons/TwitterIcon'
import Reveal, { RevealChild } from '@components/common/Reveal'

type Props = {
  page: Entry_Team_TeamMember
}

function formatYear(date?: string | null): string | null {
  if (!date) {
    return null
  }

  const parsedDate = parseISO(date)

  return format(parsedDate, 'yyyy')
}

const HeroTeamMember: React.FC<Props> = ({ page }) => {
  const numberOfPostsQuery = useGetNumberOfPostsByAuthorQuery(
    {
      userId: page.author?.id,
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const image = page.image ?? page.author?.avatar

  const teamFormatTitleKeys: {
    [key: string]: keyof Term_TeamFormats_TeamFormat
  } = {
    '': 'title',
    diverse: 'title',
    female: 'title_female',
    male: 'title_male',
  }
  const teamFormatTitleKey: keyof Term_TeamFormats_TeamFormat =
    teamFormatTitleKeys[page.author?.salutation?.value ?? ''] ?? 'title'
  const teamFormatTitles = (page.team_formats as Array<Term_TeamFormats_TeamFormat>)?.map(
    (format) => (!format ? null : format[teamFormatTitleKey])
  )

  const memberSince = formatYear(page.member_since)

  return (
    <section className={cx('bg-white pt-h-logo')}>
      <Reveal transition={{ delayChildren: 0.2, staggerChildren: 0.1 }}>
        <Container
          fluid
          className="mx-auto flex max-w-6xl flex-col gap-y-16 gap-x-16 pt-12 pb-25 lg:flex-row-reverse lg:items-center lg:justify-between lg:pb-28 lg:pt-20"
        >
          <div className="px-7 lg:w-5/12 lg:shrink-0 lg:px-0">
            <RevealChild className="mx-auto aspect-[21/25] max-h-[60vh] max-w-full lg:max-h-none">
              {image && <StatamicImage asset={image} className="h-full w-full object-cover" />}
            </RevealChild>
          </div>

          <div className="max-w-xl space-y-12 lg:flex-1 lg:pt-10">
            <div className="space-y-6">
              <RevealChild>
                <h1 className="typo-1200">{page.title}</h1>
              </RevealChild>

              <div className="space-y-11">
                <div className="flex items-start justify-between">
                  {Array.isArray(teamFormatTitles) && !!teamFormatTitles.length && (
                    <RevealChild>
                      <dl className="flex-1">
                        <dt className="typo-100-regular">Formate</dt>
                        <dd className="typo-200">{teamFormatTitles.join(', ')}</dd>
                      </dl>
                    </RevealChild>
                  )}

                  {memberSince && (
                    <RevealChild>
                      <dl className="flex-1">
                        <dt className="typo-100-regular">Mitglied seit</dt>
                        <dd className="typo-200">{memberSince}</dd>
                      </dl>
                    </RevealChild>
                  )}

                  <RevealChild>
                    <dl className="flex-1">
                      <dt className="typo-100-regular">Beiträge</dt>
                      <dd className="typo-200">{numberOfPostsQuery.data?.entries?.total ?? 0}</dd>
                    </dl>
                  </RevealChild>
                </div>

                <div className="space-y-9">
                  {!!page.quote?.length && (
                    <RevealChild>
                      <Bard
                        html={page.quote}
                        className={cx(
                          'typo-300 relative isolate inline-block',
                          'before:pointer-events-none before:absolute before:-left-6 before:-top-22 before:-z-1 before:text-[7.5rem] before:text-gray-50 before:content-["«"]',
                          'after:pointer-events-none after:absolute after:-right-4 after:-bottom-16 after:-z-1 after:text-[7.5rem] after:text-gray-50 after:content-["»"]'
                        )}
                      />
                    </RevealChild>
                  )}

                  {!!page.biography?.length && (
                    <RevealChild>
                      <Bard html={page.biography} className="typo-300-regular" />
                    </RevealChild>
                  )}
                </div>
              </div>
            </div>

            <div className="flex flex-wrap gap-x-4 gap-y-6 [@media(hover:none)]:gap-x-2">
              {page.website && (
                <RevealChild>
                  <Button href={page.website} className="shrink-0 py-1.5">
                    <ChainIcon className="-ml-0.5 mr-1 h-4 w-4" />
                    <span>zur Website</span>
                  </Button>
                </RevealChild>
              )}

              <div className="flex items-stretch gap-x-2">
                {page.facebook && (
                  <RevealChild>
                    <AppLink
                      href={page.facebook}
                      className="flex aspect-square h-10 w-10 items-center justify-center bg-gray-50 outline-none transition hover:text-red-500 focus-visible:ring"
                    >
                      <span className="sr-only">Facebook öffnen</span>
                      <FacebookIcon className="h-5 w-5" />
                    </AppLink>
                  </RevealChild>
                )}
                {page.instagram && (
                  <RevealChild>
                    <AppLink
                      href={page.instagram}
                      className="flex aspect-square h-10 w-10 items-center justify-center bg-gray-50 outline-none transition hover:text-red-500 focus-visible:ring"
                    >
                      <span className="sr-only">Instagram öffnen</span>
                      <InstagramIcon className="h-5 w-5" />
                    </AppLink>
                  </RevealChild>
                )}
                {page.linkedin && (
                  <RevealChild>
                    <AppLink
                      href={page.linkedin}
                      className="flex aspect-square h-10 w-10 items-center justify-center bg-gray-50 outline-none transition hover:text-red-500 focus-visible:ring"
                    >
                      <span className="sr-only">LinkedIn öffnen</span>
                      <LinkedInIcon className="h-5 w-5" />
                    </AppLink>
                  </RevealChild>
                )}
                {page.twitter && (
                  <RevealChild>
                    <AppLink
                      href={page.twitter}
                      className="flex aspect-square h-10 w-10 items-center justify-center bg-gray-50 outline-none transition hover:text-red-500 focus-visible:ring"
                    >
                      <span className="sr-only">Twitter öffnen</span>
                      <TwitterIcon className="h-5 w-5" />
                    </AppLink>
                  </RevealChild>
                )}
              </div>
            </div>
          </div>

          {/*<motion.div*/}
          {/*  initial="hidden"*/}
          {/*  animate="show"*/}
          {/*  transition={{ delayChildren: 0.5, staggerChildren: 0.1 }}*/}
          {/*  className="mx-auto w-full max-w-2xl space-y-8 px-6 pt-22 pb-30 text-center md:pt-14"*/}
          {/*></motion.div>*/}
        </Container>
      </Reveal>
    </section>
  )
}

export default HeroTeamMember
