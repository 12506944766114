import FormField from '@components/forms/fields/FormField'
import { Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import cx from 'classnames'
import Input from '@components/forms/fields/Input'
import Button from '@components/common/Button'
import TextArea from '@components/forms/fields/Textarea'
import Bard from '@components/common/Bard/Bard'
import { Field, Form, Maybe } from '@graphql/generated'
import { useMutation } from 'react-query'
import { jsonFetcher } from '@lib/fetcher'
import { fetchWithCsrfRetry } from '@lib/user'
import CheckmarkIcon from '@components/icons/CheckmarkIcon'
import * as Fathom from 'fathom-client'
import Reveal, { RevealChild } from '@components/common/Reveal'

type FormValues = {
  name: string
  email: string
  subject: string
  message: string
  confirm: boolean
}
const fieldHandles: (keyof FormValues)[] = ['name', 'email', 'subject', 'message', 'confirm']

type Submission = {
  submission: FormValues
  submission_created: boolean
  success: boolean
}

const ContactForm: React.FC<{
  form?: Maybe<Form>
  confirm_text?: Maybe<string>
  button_text?: Maybe<string>
  thank_you_text?: Maybe<string>
  error_text?: Maybe<string>
}> = (props) => {
  const submitMutation = useMutation(
    ['SubmitContactForm'],
    (values: FormValues) =>
      fetchWithCsrfRetry(
        jsonFetcher<Submission, FormValues>('!/forms/contact_form', values, { method: 'POST' })
      ),
    {
      onSuccess: () => {
        Fathom.trackGoal('CEGRONT4', 0)
      },
    }
  )

  const fields: { [key: Field['handle']]: Field } = Object.fromEntries(
    fieldHandles.map((handle: string) => {
      const field = props.form?.fields?.find((f) => f?.handle === handle)
      if (!field) {
        throw new Error(`Fields [${handle}] not found in contact form config`)
      }

      return [handle, field]
    })
  )

  const validationSchema = Yup.object().shape({
    name: Yup.string().required().label(fields.name.display),
    email: Yup.string().required().email().label(fields.email.display),
    subject: Yup.string().required().label(fields.subject.display),
    message: Yup.string().required().label(fields.message.display),
    confirm: Yup.boolean()
      .oneOf([true], 'Wir benötigen dein Einverständnis, um mit dir Kontakt aufnehmen zu können.')
      .label(fields.confirm.display),
  })

  if (submitMutation.isSuccess && submitMutation.data.success) {
    return <Bard html={props.thank_you_text} />
  }

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        name: '',
        email: '',
        subject: '',
        message: '',
        confirm: false,
      }}
      onSubmit={(values) => submitMutation.mutate(values)}
    >
      {({ handleSubmit, isValid }) => (
        <Reveal transition={{ delayChildren: 0.6 }}>
          <form onSubmit={handleSubmit} className="relative space-y-10">
            <div className="grid gap-4 md:grid-cols-2">
              {props.form?.honeypot && (
                <RevealChild className="absolute h-0 w-0 overflow-hidden opacity-0">
                  <FormField
                    name={props.form?.honeypot}
                    label={props.form?.honeypot}
                    labelClassName="typo-100-regular mb-1"
                  >
                    <Input type="text" />
                  </FormField>
                </RevealChild>
              )}

              <RevealChild>
                <FormField
                  name="name"
                  label={fields.name.display}
                  labelClassName="typo-100-regular mb-1"
                >
                  <Input type="text" placeholder={fields.name.display} />
                </FormField>
              </RevealChild>

              <RevealChild>
                <FormField
                  name="email"
                  label={fields.email.display}
                  labelClassName="typo-100-regular mb-1"
                >
                  <Input type="email" placeholder={fields.email.display} />
                </FormField>
              </RevealChild>

              <RevealChild className="col-span-full">
                <FormField
                  name="subject"
                  label={fields.subject.display}
                  labelClassName="typo-100-regular mb-1"
                >
                  <Input type="text" placeholder={fields.subject.display} />
                </FormField>
              </RevealChild>

              <RevealChild className="col-span-full">
                <FormField
                  name="message"
                  label={fields.message.display}
                  labelClassName="typo-100-regular mb-1"
                >
                  <TextArea
                    type="text"
                    placeholder={fields.message.display}
                    rows={5}
                    className="resize-none"
                  />
                </FormField>
              </RevealChild>

              <RevealChild className="col-span-full">
                <FormField name="confirm">
                  <div className="relative flex">
                    <input
                      type="checkbox"
                      name="confirm"
                      className="peer absolute h-0 w-0 opacity-0"
                    />
                    <div
                      className={cx(
                        'mt-[0.1875rem] mr-3.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-[theme(spacing.px)] border-2 border-blue-700 transition peer-checked:bg-blue-700 peer-focus-visible:ring'
                      )}
                    >
                      <CheckmarkIcon className="h-3/4 w-3/4 rotate-180 scale-0 text-white opacity-0 transition [.peer:checked+div>&]:rotate-0 [.peer:checked+div>&]:scale-100 [.peer:checked+div>&]:opacity-100" />
                    </div>
                    <Bard html={props.confirm_text} className="typo-200-regular cursor-pointer" />
                  </div>
                </FormField>
              </RevealChild>
            </div>

            <RevealChild className="space-y-6">
              <Button type="submit" disabled={!isValid || submitMutation.isLoading}>
                {props.button_text}
              </Button>

              {(submitMutation.isError ||
                (submitMutation.isSuccess && !submitMutation.data.success)) && (
                <Bard html={props.error_text} className="typo-200-regular text-red-500" />
              )}
            </RevealChild>
          </form>
        </Reveal>
      )}
    </Formik>
  )
}

ContactForm.defaultProps = {
  confirm_text:
    'Mit dem Absenden erklärst du dich einverstanden, dass wir deine Daten speichern und dich kontaktieren.',
  button_text: 'Absenden',
}

export default ContactForm
